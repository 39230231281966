<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        v-on:click:outside="cancelMethod"
        :persistent="loading"
    >
        <v-card>
            <v-toolbar
                color="orange lighten-2"
                class="text-h5"
            >Proposta di Modifica
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                    v-if="!loading"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid >
                <v-row v-if="overWrite && show" class="d-flex justify-center mt-2">
                    <v-sheet color="error" dark rounded class="pa-2">
                        Attenzione: La proposta di modifica esistente verrà sovrascritta.
                    </v-sheet>
                </v-row>
                <v-row no-gutters class="px-12 mt-8">
                    <v-textarea
                        auto-grow
                        rows="1"
                        label="Note di Modifica"
                        hint="Inserisci note relative alla modifica se necessario"
                        persistent-hint
                        outlined
                        v-model="variationProposalNotes"
                        :readonly="loading"
                    />
                </v-row>
            </v-container>
            <v-card-actions class="justify-end">
                <v-btn
                    color="orange lighten-1"
                    class="px-4"
                    @click="saveMethod"
                    :loading="loading"
                >
                    <v-icon left>
                        mdi-send-circle
                    </v-icon>
                    Invia
                </v-btn>
            </v-card-actions>   
        </v-card>  
    </v-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            variationProposalNotes: ""
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        overWrite: {
            type: Boolean,
            default: false
        },
        note: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        cancelMethod: function() {
            if(!this.loading)
                this.$emit('close-variation-proposal-dialog');
        },
        saveMethod: function() {
           this.$emit('send-variation-proposal', this.variationProposalNotes);
        },
    },
    watch: {
        show: function(val){
            if (val) {
                this.variationProposalNotes = this.note;
            }
        }
    },
    computed: {
    }
}
</script>