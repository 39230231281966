import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600","persistent":_vm.loading},on:{"click:outside":_vm.cancelMethod},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h5",attrs:{"color":"orange lighten-2"}},[_vm._v("Proposta di Modifica "),_c(VSpacer),(!_vm.loading)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancelMethod}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1),_c(VContainer,{attrs:{"fluid":""}},[(_vm.overWrite && _vm.show)?_c(VRow,{staticClass:"d-flex justify-center mt-2"},[_c(VSheet,{staticClass:"pa-2",attrs:{"color":"error","dark":"","rounded":""}},[_vm._v(" Attenzione: La proposta di modifica esistente verrà sovrascritta. ")])],1):_vm._e(),_c(VRow,{staticClass:"px-12 mt-8",attrs:{"no-gutters":""}},[_c(VTextarea,{attrs:{"auto-grow":"","rows":"1","label":"Note di Modifica","hint":"Inserisci note relative alla modifica se necessario","persistent-hint":"","outlined":"","readonly":_vm.loading},model:{value:(_vm.variationProposalNotes),callback:function ($$v) {_vm.variationProposalNotes=$$v},expression:"variationProposalNotes"}})],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"px-4",attrs:{"color":"orange lighten-1","loading":_vm.loading},on:{"click":_vm.saveMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-send-circle ")]),_vm._v(" Invia ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }